import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import { stagesToOptions, stageToOption } from './helpers/ice-hockey-stage-select.helper';
import { SelectMenuOptionType } from '../../../../../models/v2/general/select.model';
import { useTranslation } from 'react-i18next';
import IceHockeyStageModel from '../../../../../models/v2/Stage/Entity/ice-hockey-stage.mode';
import IceHockeyStandingsModel from '../../v2/widgets-v2/ice-hockey-standings/models/ice-hockey-standings.model';
import { IceHockeySeasonModel } from '../../../../../models/v2/ice-hockey-season/entity/ice-hockey-season.model';

type Properties = {
	onStageSelect: (stage: IceHockeyStageModel) => void;
	blockData: IceHockeyStandingsModel;
	selectedStage: IceHockeyStageModel;
	season: IceHockeySeasonModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

const IceHockeyStageSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [stagesOptions, setStagesOptions] = useState<IceHockeyStageModel[]>([]);
	const [t] = useTranslation();

	useEffect(() => {
		if (props.season && props.season.stages) {
			setStagesOptions(props.season.stages);
		}
	}, [props.season]);

	useEffect(() => {
		if (stagesOptions.length && !props.selectedStage.id) {
			props.onStageSelect(stagesOptions[0]);
		}
	}, [stagesOptions]);

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('stage')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={stagesToOptions(stagesOptions)}
					value={props.selectedStage && props.selectedStage.id ? stageToOption(props.selectedStage) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: SelectMenuOptionType) => {
						if (selection) {
							props.onStageSelect(selection.data);
						} else {
							props.onStageSelect({} as IceHockeyStageModel);
						}
					}}
				/>
				{!props.isValid && !props.selectedStage && <ErrorHandler errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default IceHockeyStageSelectComponent as React.ComponentType<Properties>;
